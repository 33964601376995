import axios, { AxiosResponse } from "axios";
import { APIUrl, domainUrl } from "../../App";

export function loginApiCall(
  email: string,
  password: string,
  urlId: any,
  isRecruiter: boolean,
  isOAuth: boolean,
  creds: any,
): Promise<AxiosResponse<any>> {
  return axios.post(`/Auth/Login`, {
    strLoginId: email,
    strPassword: password,
    intUrlId: urlId?.urlId,
    intAccountId: urlId?.accountId,
    strUrl: domainUrl,
    intUserTypeId: isRecruiter ? 2 : 1,
  });
}
export function AccountCreateNUpdate(payload: {}): Promise<AxiosResponse<any>> {
  return axios.post(`/Account/AccountCreateNUpdate`, payload);
}

export function UserCreation(payload: {}): Promise<AxiosResponse<any>> {
  return axios.post(`/Auth/UserCreation`, payload);
}

// otp api
export function getLoginOTP(email: string) {
  return axios.get(`/Auth/GetLoginOTP?mailAddress=${email}`);
}

// Api for getDownlloadFileView
export function getDownlloadFileView(id: string | number) {
  return axios.get(`${APIUrl}/Document/DownloadFile?id=${id}`);
}

export function geBuDDL(accId: number, buId: number) {
  return axios.get(
    `/MasterData/PeopleDeskAllLanding?TableName=${"BusinessUnit"}&AccountId=${accId}&BusinessUnitId=${buId}&intId=${0}`
  );
}

export function getMenuList(intUserId: number) {
  return axios.get(`/Menu/GetMenuListPermissionWise?userId=${intUserId}`);
}

export function getKeywords(orgId: number, lang: string) {
  return axios.get(
    `/QueryData/GetGlobalCultures?AccountId=${orgId}&Language=${lang}`
  );
}

export function getPermissionList(userId: number) {
  return axios.get(
    `/Menu/GetMenuUserPermissionForActivityCheck?userId=${userId}`
  );
}
export function sendNotificationToUser(accountId: any, userid: any) {
  return axios.get(
    `/Notification/GetNotificationCount?userId=${userid}&accountId=${accountId}`
  );
}
export function googleLoginApiCall(
  payload: any
): Promise<AxiosResponse<any>> {
  return axios.post(`/Auth/LoginOAuth2G`, payload);
}

export function getUserOrganization(
  payload: any
): Promise<AxiosResponse<any>> {
  return axios.post(`/Auth/UserOrganization`, payload);
}
